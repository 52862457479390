(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificateDeleteController',CertificateDeleteController);

    CertificateDeleteController.$inject = ['$uibModalInstance', 'entity', 'Certificate'];

    function CertificateDeleteController($uibModalInstance, entity, Certificate) {
        var vm = this;

        vm.certificate = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Certificate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
