(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificateDetailController', CertificateDetailController);

    CertificateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Certificate'];

    function CertificateDetailController($scope, $rootScope, $stateParams, previousState, entity, Certificate) {
        var vm = this;

        vm.certificate = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('capacitacionCertificacionApp:certificateUpdate', function(event, result) {
            vm.certificate = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
