(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationEnrollmentController', CertificationEnrollmentController);

    CertificationEnrollmentController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', /*'Person',*/'$state', 'User'];

    function CertificationEnrollmentController ($timeout, $scope, $stateParams, entity, /*Person,*/$state, User) {
        var vm = this;

        vm.person = entity;
        vm.clear = clear;
        vm.save = save;
        User.current = null;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            User.current = vm.person;
            /*vm.isSaving = true;
            if (vm.person.id !== null) {
                Person.update(vm.person, onSaveSuccess, onSaveError);
            } else {
                Person.save(vm.person, onSaveSuccess, onSaveError);
            }*/
            $state.go('certification.course');
        }

        function onSaveSuccess (result) {
            $scope.$emit('capacitacionCertificacionApp:personUpdate', result);
            vm.isSaving = false;
            $state.go('certification.course');
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        // TODO: this would be best to have it in a validation directive to be reusable.
        $scope.$watch('vm.person.documentNumber', function(newRut, oldRut) {
            if (vm.person.documentType == "rut") {
                if (newRut === oldRut || newRut == undefined) { return; } // Skip initial load or empty value
                validateRut(newRut);
            }
        });

        $scope.$watch('vm.person.documentType', function(newType, oldType) {
            if (newType != "rut") {
                SetRutValidityToValid();
            } else {
                validateRut(vm.person.documentNumber);
            }
        });

        function SetRutValidityToValid() {
            $scope.editForm.documentNumber.$setValidity('invalidRutFormat', true);
            $scope.editForm.documentNumber.$setValidity('invalidRutVerifierDigit', true);
        }

        function validateRut(rut) {
            if (/^[0-9]+[-|‐]{1}[0-9K]{1}$/.test(rut)) {
                $scope.editForm.documentNumber.$setValidity('invalidRutFormat', true);
            } else {
                $scope.editForm.documentNumber.$setValidity('invalidRutFormat', false);
            }

            // Remove any dots and dashes from the RUT
            var cleanRut = rut.replace(/[\.-]/g, '');

            // Validate the RUT using the algorithm
            if (isValidRutVerifierDigit(cleanRut)) {
                $scope.editForm.documentNumber.$setValidity('invalidRutVerifierDigit', true);
            } else {
                $scope.editForm.documentNumber.$setValidity('invalidRutVerifierDigit', false);
            }
        }

        function isValidRutVerifierDigit(rut) {
            if (!rut || rut.length < 3) {
                return false;
            }

            var rutNumbers = parseInt(rut.slice(0, -1).replace(/\./g, ''), 10);
            var rutLastDigit = rut.slice(-1).toUpperCase();
            var M = 0, S = 1;

            for (; rutNumbers; rutNumbers = Math.floor(rutNumbers / 10)) {
                S = (S + (rutNumbers % 10) * (9 - (M++ % 6))) % 11;
            }
            var expectedVerifierDigit =  (S ? S - 1 : 'K').toString();
            return expectedVerifierDigit === rutLastDigit;
        }
    }
})();

