(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationQuestionnaireController', CertificationQuestionnaireController);

    CertificationQuestionnaireController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', /*'Person',*/'$state', 'User','Course'];

    function CertificationQuestionnaireController ($timeout, $scope, $stateParams, entity, /*Person,*/ $state, User,Course) {
        var vm = this;

        if (!User.current) {
            $state.go('certification.enrollment');
        } else {
            if (User.current.result != undefined) {
                $state.go('certification.result');
            }

            User.current.testInit = true;

            vm.entity = entity;
            vm.questionnaire = entity.questions;
            vm.clear = clear;
            vm.save = save;

            $timeout(function (){
                angular.element('.form-group:eq(1)>input').focus();
            });

            function clear () {
                $uibModalInstance.dismiss('cancel');
            }

            function save () {
                //vm.isSaving = true;
                // TODO: use callback:
                var answeredQuestionAmount = 0;
                for (var q_index = 0; q_index < vm.questionnaire.length; q_index++) {
                    var question = vm.questionnaire[q_index]

                    var answered = false;
                    for (var c_index = 0; c_index < question.choices.length; c_index++) {
                        answered = answered || question.choices[c_index].userAnswer
                    }
                    if (answered)
                        ++answeredQuestionAmount;
                }
                if (answeredQuestionAmount != 10) {
                    vm.userNotAnsweredAllQuestions = true;
                    vm.isSaving = false;
                } else {

                    var correctQuestionAmount = 0;
                    for (var q_index = 0; q_index < vm.questionnaire.length; q_index++) {
                        var question = vm.questionnaire[q_index]

                        var correct = true;
                        for (var c_index = 0; c_index < question.choices.length; c_index++) {
                            correct = correct && question.choices[c_index].correct == question.choices[c_index].userAnswer
                        }
                        if (correct)
                            ++correctQuestionAmount;
                    }
                    User.current.result = correctQuestionAmount;

                    for (var q_index = 0; q_index < vm.questionnaire.length; q_index++) {
                        var question = vm.questionnaire[q_index]
                        for (var c_index = 0; c_index < question.choices.length; c_index++) {
                            question.choices[c_index].correct = question.choices[c_index].userAnswer
                        }
                    }

                    vm.entity.questions = vm.questionnaire;
                    Course.submitExam({id : vm.entity.id},vm.entity, function(r) {
                        console.log(r);
                        User.current.examResult = r;
                        $state.go('certification.result');
                    })

                                    /*
                    if (vm.quetionnaire.id !== null) {
                       Person.update(vm.person, onSaveSuccess, onSaveError);

                    } else {
                        Person.save(vm.person, onSaveSuccess, onSaveError);
                    }*/
                }
            }

            function onSaveSuccess (result) {
                //$scope.$emit('capacitacionCertificacionApp:personUpdate', result);
                vm.isSaving = false;
                $state.go('certification.result');
            }

            function onSaveError () {
                vm.isSaving = false;
            }
        }


    }
})();

